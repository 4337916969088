'use client';

import Image from 'next/image';
import Link from 'next/link';

import Container from '@/components/Container';
import Logo from '@/components/Logo';
import { clickVisitLandingPageLink } from '@/utils/analytics/log-event';

import SearchForm from './components/SearchForm';
import premiumButtonImage from './images/premium-button.png';

const Component: React.FC = () => {
  return (
    <header className="border-b border-solid border-gray-200 bg-gray-100 py-2">
      <Container>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="mr-8">
              <Logo />
            </div>

            {/* PC版検索フォーム */}
            <div className="hidden md:block">
              <SearchForm />
            </div>
          </div>

          <div className="flex items-center">
            <Link
              passHref
              href="/lp/base"
              className="max-w-[140px]"
              onClick={() => clickVisitLandingPageLink('header_premium_button')}
            >
              <Image
                priority
                src={premiumButtonImage}
                alt="シェークハンズ プレミアムサービスの案内ページへのリンク"
              />
            </Link>
          </div>
        </div>

        {/* スマホ版検索フォーム */}
        <div className="mt-2 md:hidden">
          <SearchForm />
        </div>
      </Container>
    </header>
  );
};

export default Component;

import { Spinner } from '@chakra-ui/react';
import classnames from 'classnames';
import { forwardRef } from 'react';

type Props = Omit<
  React.ComponentProps<'button'>,
  'children' | 'disabled' | 'className'
> & {
  caption: string;
  canSubmit?: boolean;
  isSubmitting?: boolean;
};

const Component = forwardRef<HTMLButtonElement, Props>(
  ({ caption, canSubmit = true, isSubmitting = false, ...props }, ref) => (
    <button
      {...props}
      ref={ref}
      disabled={!canSubmit || isSubmitting}
      className={classnames(
        'relative flex h-[30px] w-[70px] items-center justify-center rounded-sm border border-solid border-gray-300 bg-white',
        {
          'hover:bg-gray-50 active:bg-gray-200': canSubmit && !isSubmitting,
        },
      )}
    >
      <span className={classnames('text-sm', isSubmitting && 'invisible')}>
        {caption}
      </span>

      {isSubmitting && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
          <Spinner size="sm" className="text-base" />
        </div>
      )}
    </button>
  ),
);

export default Component;

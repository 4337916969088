// prettier-ignore
export const EventName = {
  ClickLPConversionButton: 'click_lp_conversion_button',
  ClickLPCheckCastVideosButton: 'click_lp_check_cast_videos_button',
  ClickLPFAQItem: 'click_lp_faq_item',
  ClickLPSignUpFormSubmit: 'click_lp_sign_up_form_submit',
  ClickLPFooterHelpLink: 'click_lp_footer_help_link',
  ClickLPFooterCompanyDetailsLink: 'click_lp_footer_company_details_link',
  ClickLPFooterPrivacyLink: 'click_lp_footer_privacy_link',
  ClickLPFooterMembersRulesLink: 'click_lp_footer_members_rules_link',
  ClickLPFooterRulesLink: 'click_lp_footer_rules_link',
  StartLPSignUpFormInput: 'start_lp_sign_up_form_input',
  ClickVisitTopPageLink: 'click_visit_top_page_link',
  ClickVisitTtmoviePageLink: 'click_visit_ttmovie_page_link',
  ClickVisitCategoryPageLink: 'click_visit_category_page_link',
  ClickVisitTagPageLink: 'click_visit_tag_page_link',
  ClickVisitLandingPageLink: 'click_visit_landing_page_link',
  ClickVisitStaticPageLink: 'click_visit_static_page_link',
  ClickVisitMyshandsStaticPageLink: 'click_visit_myshands_static_page_link',
  ClickVisitPostPageLink: 'click_visit_post_page_link',
  ClickVisitHiraokaTheoryPageLink: 'click_visit_hiraoka_theory_page_link',
  ClickVisitCoursePageLink: 'click_visit_course_page_link',
  ClickVisitBlogPageLink: 'click_visit_blog_page_link',
  ClickVisitSearchPageLink: 'click_visit_search_page_link',
  ClickVisitMyshandsSigninPageLink:'click_visit_myshands_signin_page_link',
  ClickVisitClubPageLink:'click_visit_club_page_link',
  ClickVisitSnsPageLink: 'click_visit_sns_page_link',
  ClickVisitHelpPageLink: 'click_visit_help_page_link',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EventName = (typeof EventName)[keyof typeof EventName];

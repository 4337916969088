import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter, usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { schema, Schema } from './form-schema';

const useHook = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { isValid: canSubmit },
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      keyword: '',
    },
  });

  const submit = handleSubmit(data => {
    setIsSubmitting(true);
    const encoded = encodeURIComponent(data.keyword);

    router.push(`/search/${encoded}`);
  });

  /**
   * URLのパスパラメタを解析して初期値を設定する
   */
  useEffect(() => {
    setIsSubmitting(false);

    const match = pathname?.match(/^\/search\/([^/]+)$/);

    if (match && match[1]) {
      const keyword = decodeURIComponent(match[1]);

      reset({ keyword });
    }
  }, [reset, pathname]);

  return {
    submit,
    register,
    canSubmit,
    isSubmitting,
  };
};

export default useHook;

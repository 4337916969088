import Image from 'next/image';
import Link from 'next/link';

import { clickVisitTopPageLink } from '@/utils/analytics/log-event';

import logoImage from './images/logo.png';

const Component: React.FC = () => {
  return (
    <Link
      href="/"
      className="block w-[160px] md:w-[200px]"
      onClick={() => clickVisitTopPageLink('header_logo')}
    >
      <Image src={logoImage} priority alt="動画で学ぶ卓球 シェークハンズ" />
    </Link>
  );
};

export default Component;

import { clickVisitSearchPageLink } from '@/utils/analytics/log-event';

import SubmitButton from './components/SubmitButton';
import useHook from './hook';

const Component: React.FC = () => {
  const { submit, register, canSubmit, isSubmitting } = useHook();

  return (
    <form noValidate onSubmit={submit} className="flex items-stretch">
      <input
        {...register('keyword')}
        type="text"
        placeholder="技名・選手名・コーチ名"
        className="mr-2 flex-1 rounded-sm border border-solid border-gray-300 bg-white px-2 outline-none"
      />

      <SubmitButton
        caption="動画検索"
        canSubmit={canSubmit}
        isSubmitting={isSubmitting}
        onClick={() => clickVisitSearchPageLink('header_search_button')}
      />
    </form>
  );
};

export default Component;

import { EventName } from './event-name';

declare const dataLayer: unknown[];

type LogEventParams = Record<string, unknown>;

type LogEventInput = {
  name: string;
  params?: LogEventParams;
};

// GA4でのイベントパラメタの長さの上限は100文字
// 収集と設定の上限（ https://support.google.com/analytics/answer/9267744?hl=ja ）
const maxEventParamsLength = 100;

const logEvent = ({ name, params = {} }: LogEventInput) => {
  const stringifiedParams = JSON.stringify(params);

  if (stringifiedParams.length > maxEventParamsLength) {
    throw new Error('[analytics] payload is too long');
  }

  const payload: Record<string, string> = {
    event: 'custom_event',
    custom_event_name: name,
    custom_event_params: stringifiedParams,
  };

  // eslint-disable-next-line no-console
  console.info(JSON.stringify(payload, null, 2));

  dataLayer.push(payload);
};

export const clickVisitTopPageLink = (place: 'header_logo') => {
  logEvent({ name: EventName.ClickVisitTopPageLink, params: { place } });
};

export const clickVisitTtmoviePageLink = (place: 'drawer_menu') => {
  logEvent({ name: EventName.ClickVisitTtmoviePageLink, params: { place } });
};

export const clickVisitCategoryPageLink = (
  place: 'new_blog_area' | 'blog_list_page_return_link',
  category: string,
) => {
  logEvent({
    name: EventName.ClickVisitCategoryPageLink,
    params: { category_slug: category, place },
  });
};

export const clickVisitLandingPageLink = (
  place:
    | 'footer_banner'
    | 'sidebar_banner'
    | 'single_band_top'
    | 'single_band_bottom'
    | 'single_under_player'
    | 'single_under_content'
    | 'single_free_under_player'
    | 'single_free_under_content'
    | 'coach_blog_band_top'
    | 'archive_band_bottom'
    | 'keyword_band_top'
    | 'keyword_band_bottom'
    | 'page_band_top'
    | 'page_band_bottom'
    | 'page_band_bottom'
    | 'header_premium_button'
    | 'footer_tags_list'
    | 'blog_under_content'
    | 'lesson_courses_under_player'
    | 'lesson_courses_under_content'
    | 'help_premium_button'
    | 'profile_under_content',
  type?: string,
) => {
  logEvent({
    name: EventName.ClickVisitLandingPageLink,
    params: { type, place },
  });
};

export const clickVisitTagPageLink = (
  place: 'footer_tags_list' | 'featured_tags_list',
  slug?: string,
) => {
  logEvent({ name: EventName.ClickVisitTagPageLink, params: { place, slug } });
};

export const clickVisitStaticPageLink = (
  place: 'footer_tags_list' | 'company_tags_list',
  slug: string,
) => {
  logEvent({
    name: EventName.ClickVisitStaticPageLink,
    params: { category_slug: slug, place },
  });
};

export const clickVisitMyshandsStaticPageLink = (
  place: 'footer_tags_list' | 'rules_tags_list' | 'support_tags_list',
  slug: string,
) => {
  logEvent({
    name: EventName.ClickVisitMyshandsStaticPageLink,
    params: { category_slug: slug, place },
  });
};

export const clickVisitPostPageLink = (
  place:
    | 'sidebar_popular_posts_list'
    | 'sidebar_special_posts_list'
    | 'published_posts_list'
    | 'slide_area'
    | 'recent_posts_list'
    | 'excerpt_tag_posts_list'
    | 'search_results_list'
    | 'coach_area'
    | 'technique_area'
    | 'related_area'
    | 'lesson_courses_list',
  type?: string,
) => {
  logEvent({ name: EventName.ClickVisitPostPageLink, params: { place, type } });
};

export const clickVisitHiraokaTheoryPageLink = (
  place: 'featured_tags_list',
) => {
  logEvent({
    name: EventName.ClickVisitHiraokaTheoryPageLink,
    params: { place },
  });
};

export const clickVisitCoursePageLink = (
  place:
    | 'lesson_course_area'
    | 'open_lesson_course_banner'
    | 'close_lesson_course_banner'
    | 'hiraoka_lesson_course'
    | 'itagaki_lesson_course',
) => {
  logEvent({
    name: EventName.ClickVisitCoursePageLink,
    params: { place },
  });
};

export const clickVisitBlogPageLink = (
  place:
    | 'staff_blog'
    | 'itagaki_blog'
    | 'hiraoka_blog'
    | 'ueda_blog'
    | 'takagi_column'
    | 'recent_posts_list',
) => {
  logEvent({
    name: EventName.ClickVisitBlogPageLink,
    params: { place },
  });
};

export const clickVisitSearchPageLink = (place: 'header_search_button') => {
  logEvent({
    name: EventName.ClickVisitSearchPageLink,
    params: { place },
  });
};

export const clickVisitMyshandsSigninPageLink = (
  place: 'help_login_button',
) => {
  logEvent({
    name: EventName.ClickVisitMyshandsSigninPageLink,
    params: { place },
  });
};

export const clickVisitClubPageLink = (place: 'offline_service_area') => {
  logEvent({ name: EventName.ClickVisitClubPageLink, params: { place } });
};

export const clickVisitSnsPageLink = (place: 'sns_tags_list', type: string) => {
  logEvent({ name: EventName.ClickVisitSnsPageLink, params: { place, type } });
};

export const clickLpConversionButton = (
  place:
    | 'first_view'
    | 'header'
    | 'conversion_banner_top'
    | 'conversion_banner_bottom'
    | 'sample_movie',
) => {
  logEvent({
    name: EventName.ClickLPConversionButton,
    params: { place },
  });
};

export const clickLpCheckCastVideosButton = (cast: string) => {
  logEvent({
    name: EventName.ClickLPCheckCastVideosButton,
    params: { cast },
  });
};

export const clickLpFaqItem = (question: string) => {
  logEvent({
    name: EventName.ClickLPFAQItem,
    params: { question },
  });
};

export const startLpSignUpFormInput = (inputName: string) => {
  logEvent({
    name: EventName.StartLPSignUpFormInput,
    params: { inputName },
  });
};

export const clickLpSignUpFormSubmit = () => {
  logEvent({
    name: EventName.ClickLPSignUpFormSubmit,
  });
};

export const clickLpFooterHelpLink = () => {
  logEvent({
    name: EventName.ClickLPFooterHelpLink,
  });
};

export const clickLpFooterCompanyDetailsLink = () => {
  logEvent({
    name: EventName.ClickLPFooterCompanyDetailsLink,
  });
};

export const clickLpFooterPrivacyLink = () => {
  logEvent({
    name: EventName.ClickLPFooterPrivacyLink,
  });
};

export const clickLpFooterMembersRulesLink = () => {
  logEvent({
    name: EventName.ClickLPFooterMembersRulesLink,
  });
};

export const clickLpFooterRulesLink = () => {
  logEvent({
    name: EventName.ClickLPFooterRulesLink,
  });
};

export const clickVisitHelpPageLink = (place: 'footer_area') => {
  logEvent({ name: EventName.ClickVisitHelpPageLink, params: { place } });
};

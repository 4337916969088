'use client';

import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

import Container from '@/components/Container';
import {
  clickVisitTagPageLink,
  clickVisitHelpPageLink,
  clickVisitStaticPageLink,
} from '@/utils/analytics/log-event';

type List = {
  title: string;
  titleRightArea?: JSX.Element;
  menuLists: {
    path: string;
    caption: string;
  }[];
};

const Component: React.FC = () => {
  const createSlugFunction = (path: string): string => {
    const lastSlashNumber: number = path.lastIndexOf('/');

    return path.substring(lastSlashNumber + 1);
  };

  // prettier-ignore
  const castList: List = {
    title: '出演者',
    titleRightArea: (
      <Link prefetch={false} href="/profiles" className='flex items-center rounded px-1 py-0.5 text-sm active:bg-gray-200'>
        <span className='mr-0.5'>出演者一覧</span>
        <FontAwesomeIcon fixedWidth icon={faArrowCircleRight} className='text-gray-600' size="sm" />
      </Link>
    ),
    menuLists: [
      { path: '/profiles/hiraoka-yoshihiro', caption: '平岡 義博' },
      { path: '/profiles/qiu-jianxin', caption: '邱 建新' },
      { path: '/profiles/itagaki-koji', caption: '板垣 孝司' },
      { path: '/fujii-takafumi', caption: '藤井 貴文' },
      { path: '/hiratsuka-yoichiro', caption: '平塚 陽一郎' },
      { path: '/chan-kazuhiro', caption: '張(チャン) 一博' },
      { path: '/hiraya-koudai', caption: '平屋 広大' },
      { path: '/morizono-masataka', caption: '森薗 政崇' },
      { path: '/tsuboi-yuma', caption: '坪井 勇磨' },
      { path: '/profiles/oikawa-mizuki', caption: '及川 瑞基' },
      { path: '/sanbe-kouhei', caption: '三部 航平' },
      { path: '/yamamoto-rei', caption: '山本 怜' },
      { path: '/suzuki-rika', caption: '鈴木 李茄' },
      { path: '/profiles/ando-minami', caption: '安藤 みなみ' },
      { path: '/takeoka-junki', caption: '竹岡 純樹' },
      { path: '/archives/16168', caption: 'ビーボ' },
      { path: '/moriya-tsubasa', caption: '森屋 翼' },
      { path: '/so-eka', caption: '宋 恵佳' },
      { path: '/yoshimura-maharu', caption: '吉村 真晴' },
      { path: '/yoshimura-kazuhiro', caption: '吉村 和弘' },
      { path: '/shoji-yuki', caption: '庄司有貴' },
      { path: '/matsuzawa-marina', caption: '松澤 茉里奈' },
      { path: '/sato-mitsuhiko', caption: '佐藤 光彦' },
      { path: '/shiono-masato', caption: '塩野 真人' },
      { path: '/amano-yuu', caption: '天野 優' },
      { path: '/ohno-sayuri', caption: '大野 さゆり' },
      { path: '/kondo-kinji', caption: '近藤 欽司' },
      { path: '/morizono-miduki', caption: '森薗 美月' },
      { path: '/yoshida-kaii', caption: '吉田 海偉' },
      { path: '/morizono-misaki', caption: '森薗 美咲' },
      { path: '/profiles/aida-satoshi', caption: '英田 理志' },
      { path: '/profiles/narumoto-ayami', caption: '成本 綾海' },
      { path: '/profiles/ueda-jin', caption: '上田 仁' },
      { path: '/ishigaki_yuka', caption: '石垣 優香' },
      { path: '/kawaguchi_yoyo', caption: '川口 陽陽' },
    ],
  };

  // prettier-ignore
  const skillList: List = {
    title: '技術・テクニック',
    menuLists: [
      { path: '/keyword/largesize-ball', caption: 'ラージボール' },
      { path: '/keyword/smash', caption: 'スマッシュ' },
      { path: '/keyword/omote', caption: '表ソフト' },
      { path: '/keyword/tsubudaka', caption: '粒高ラバー' },
      { path: '/keyword/serve_oji', caption: '王子サーブ' },
      { path: '/keyword/topspin', caption: 'ドライブ' },
      { path: '/keyword/chu-pen', caption: '中ペン' },
      { path: '/keyword/acute_angle', caption: 'AA理論（鋭角）' },
      { path: '/keyword/single_practice', caption: '一人でできる練習' },
      { path: '/keyword/comfortable_catch', caption: 'CC理論（打点）' },
      { path: '/keyword/chop_man', caption: 'カットマン' },
      { path: '/keyword/serve_involution', caption: '巻き込みサーブ' },
      { path: '/keyword/pen', caption: 'ペンホルダー' },
      { path: '/keyword/training', caption: 'ラケットを使わない練習' },
      { path: '/keyword/spiral', caption: 'スパイラル打法' },
      { path: '/keyword/many_ball_practice', caption: '多球練習' },
      { path: '/keyword/scapula_scope', caption: 'SS理論' },
      { path: '/keyword/forehand_beginner', caption: 'フォアハンド(初心者向け)' },
      { path: '/keyword/backdrive', caption: 'バックドライブ' },
      { path: '/keyword/doubles', caption: 'ダブルス' },
    ],
  };

  const footerBottomLists: List[] = [skillList, castList];

  return (
    <>
      <div className="bg-gray-100 py-8 md:p-8">
        <Container>
          <div className="mb-10">
            {footerBottomLists.map(({ title, titleRightArea, menuLists }) => (
              <div key={title} className="mb-10">
                <div className="mb-3 flex items-center space-x-2">
                  <div className="text-sm font-bold text-gray-600">{title}</div>

                  {titleRightArea && <div>{titleRightArea}</div>}
                </div>
                <ul className="mr-32 grid min-w-[250px] grid-cols-2 md:mr-0 md:grid-cols-4 md:gap-x-28">
                  {menuLists.map(({ caption, path }) => (
                    <li key={path} className="w-auto p-0 lg:w-4/12">
                      <Link
                        className="whitespace-nowrap text-xs leading-normal text-gray-500 hover:text-gray-400"
                        onClick={() => {
                          if (path.includes('keyword')) {
                            clickVisitTagPageLink(
                              'footer_tags_list',
                              createSlugFunction(path),
                            );
                          } else {
                            clickVisitStaticPageLink(
                              'footer_tags_list',
                              createSlugFunction(path),
                            );
                          }
                        }}
                        prefetch={false}
                        href={path}
                      >
                        {caption}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="mb-4 flex justify-center">
            <Link
              href="/help"
              prefetch={false}
              className="text-sm text-gray-500 underline"
              onClick={() => clickVisitHelpPageLink('footer_area')}
            >
              ヘルプ
            </Link>
          </div>
          <div className="text-center text-sm text-gray-600">
            &copy;2013-{new Date().getFullYear()} 株式会社シェークハンズ
          </div>
        </Container>
      </div>
    </>
  );
};

export default Component;
